import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo/seo"
import hAdPlaceholder from '../images/placeholder/horizontal-ad-placeholder.png';
import vAdPlaceholder from '../images/placeholder/vertical-ad-placeholder.png';


const BlogPostTemplate = ({ pageContext, data }) => {
  var post = pageContext.post;

  const [prevPost, setPrevPost] = React.useState({
    title: '',
    slug: ''
  });
  const [nextPost, setNextPost] = React.useState({
    title: '',
    slug: ''
  });


  React.useEffect(() => {
    data.allBlogDataJson.edges.forEach((el, i) => {
      if(el.node.slug === post.slug) {
        if(i > 0) {
          setPrevPost({
            title: data.allBlogDataJson.edges[i-1].node.H1,
            slug: data.allBlogDataJson.edges[i-1].node.slug
          })
        }
        if(i < (data.allBlogDataJson.edges.length - 1)) {
          setNextPost({
            title: data.allBlogDataJson.edges[i+1].node.H1,
            slug: data.allBlogDataJson.edges[i+1].node.slug
          })
        }
      }
    })
  }, []);


  return (
    <Layout>



<Seo title={post.H1} h1={post.H1} description={post.excerpt} slug={post.slug} img={post.IMAGE_URL} time={post.date} category={post.category} />



      <div className="single-post">
        <div className='row'>
          <div className='col-lg-8'>
            <div className="container-fluid p-0">
              <img
                src={post.IMAGE_URL}
                alt={post.H1 && post.H1.replace('?','')}
                width="100%"
                height="100%"
                className="mt-5"
              />
              <h1 className="post-title py-4">{post.H1}</h1>
              <p dangerouslySetInnerHTML={{ __html: post.H1_content }}>
                {/* {post.H1_content} */}
              </p>
              <div className='horizontal-ad-wrapper'>
                {
                  data.allAdDataJson.edges.filter((el) => {
                    return el.node.type === "blogpost_mid_1";
                  }).map((el, i) => {
                    return(
                      <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                        <img src={el.node.image === ""? hAdPlaceholder: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                      </a>
                    )
                  })
                }
              </div>
              <h2>{post.H2_1}</h2>
              <p dangerouslySetInnerHTML={{ __html: post.H2_1_content }}>
                {/* {post.H2_1_content} */}
              </p>
              <div className='horizontal-ad-wrapper'>
                {
                  data.allAdDataJson.edges.filter((el) => {
                    return el.node.type === "blogpost_mid_2";
                  }).map((el, i) => {
                    return(
                      <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                        <img src={el.node.image === ""? hAdPlaceholder: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                      </a>
                    )
                  })
                }
              </div>
              <h2>{post.H2_2}</h2>
              <p dangerouslySetInnerHTML={{ __html: post.H2_2_content }}>
                {/* {post.H2_2_content} */}
              </p>
              <div className='horizontal-ad-wrapper'>
                {
                  data.allAdDataJson.edges.filter((el) => {
                    return el.node.type === "blogpost_mid_3";
                  }).map((el, i) => {
                    return(
                      <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                        <img src={el.node.image === ""? hAdPlaceholder: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                      </a>
                    )
                  })
                }
              </div>
              <h2>{post.H2_3}</h2>
              <p dangerouslySetInnerHTML={{ __html: post.H2_3_content }}>
                {/* {post.H2_3_content} */}
              </p>
              <div className='horizontal-ad-wrapper'>
                {
                  data.allAdDataJson.edges.filter((el) => {
                    return el.node.type === "blogpost_mid_4";
                  }).map((el, i) => {
                    return(
                      <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                        <img src={el.node.image === ""? hAdPlaceholder: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                      </a>
                    )
                  })
                }
              </div>
              {post.hasOwnProperty('H2_4')?
                <><h2>{post.H2_4}</h2>
                  <p dangerouslySetInnerHTML={{ __html: post.H2_4_content }}>
                    {/* {post.H2_4_content} */}
                  </p>
                  <div className='horizontal-ad-wrapper'>
                    {
                      data.allAdDataJson.edges.filter((el) => {
                        return el.node.type === "blogpost_mid_5";
                      }).map((el, i) => {
                        return(
                          <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                            <img src={el.node.image === ""? hAdPlaceholder: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                          </a>
                        )
                      })
                    }
                  </div>
                </>:null}
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='widget-wrapper share-widget'>
              <h5>Share About Us</h5>
              <div className='widget-content'>
                <a href={`https://twitter.com/intent/tweet?text=Check this out! ${typeof window !== "undefined"? window.location: ''}`} className='share-link' title='Twitter' target='_blank'>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" fill="#5DA1EF"/>
                    <path d="M28.7386 15.4859C28.7526 15.6828 28.7526 15.8797 28.7526 16.0766C28.7526 22.0812 24.2158 29 15.9239 29C13.3693 29 10.9962 28.2547 9 26.961C9.36296 27.0031 9.71191 27.0172 10.0888 27.0172C12.1967 27.0172 14.1371 26.3 15.6866 25.0766C13.7043 25.0344 12.0431 23.7266 11.4708 21.9266C11.75 21.9687 12.0292 21.9969 12.3224 21.9969C12.7272 21.9969 13.132 21.9406 13.5089 21.8422C11.4429 21.4203 9.89337 19.5922 9.89337 17.3844V17.3281C10.4936 17.6656 11.1916 17.8766 11.9314 17.9047C10.717 17.089 9.9213 15.6969 9.9213 14.1218C9.9213 13.2781 10.1446 12.5047 10.5355 11.8297C12.7551 14.5859 16.0914 16.3859 19.8325 16.5828C19.7627 16.2453 19.7208 15.8938 19.7208 15.5422C19.7208 13.039 21.731 11 24.2297 11C25.5279 11 26.7005 11.5484 27.5241 12.4344C28.5431 12.2375 29.5203 11.8578 30.3858 11.3375C30.0507 12.3922 29.3388 13.2782 28.4036 13.8406C29.3109 13.7422 30.1904 13.489 31 13.1375C30.3859 14.0375 29.6181 14.839 28.7386 15.4859V15.4859Z" fill="white"/>
                  </svg>
                </a>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=Check this out! ${typeof window !== "undefined"? window.location: ''}`} className='share-link' title='Facebook' target='_blank'>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" fill="#4577EE"/>
                    <path d="M28.75 10H12.25C11.6533 10 11.081 10.2258 10.659 10.6276C10.2371 11.0295 10 11.5745 10 12.1429V27.8571C10 28.4255 10.2371 28.9705 10.659 29.3724C11.081 29.7742 11.6533 30 12.25 30H18.6836V23.2004H15.7305V20H18.6836V17.5607C18.6836 14.7862 20.418 13.2536 23.0744 13.2536C24.3466 13.2536 25.6769 13.4696 25.6769 13.4696V16.1929H24.2111C22.7669 16.1929 22.3164 17.0464 22.3164 17.9219V20H25.5405L25.0248 23.2004H22.3164V30H28.75C29.3467 30 29.919 29.7742 30.341 29.3724C30.7629 28.9705 31 28.4255 31 27.8571V12.1429C31 11.5745 30.7629 11.0295 30.341 10.6276C29.919 10.2258 29.3467 10 28.75 10V10Z" fill="white"/>
                  </svg>
                </a>
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${typeof window !== "undefined"? window.location: ''}&title=${post.H1}`} className='share-link' title='Linked-in' target='_blank'>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" fill="#4167AF"/>
                    <path d="M30.3571 9H10.6377C9.73415 9 9 9.67969 9 10.5141V28.4859C9 29.3203 9.73415 30 10.6377 30H30.3571C31.2607 30 32 29.3203 32 28.4859V10.5141C32 9.67969 31.2607 9 30.3571 9ZM15.9513 27H12.5424V16.9781H15.9565V27H15.9513ZM14.2469 15.6094C13.1533 15.6094 12.2703 14.7984 12.2703 13.8047C12.2703 12.8109 13.1533 12 14.2469 12C15.3353 12 16.2234 12.8109 16.2234 13.8047C16.2234 14.8031 15.3404 15.6094 14.2469 15.6094V15.6094ZM28.7297 27H25.3208V22.125C25.3208 20.9625 25.2951 19.4672 23.5496 19.4672C21.7732 19.4672 21.5011 20.7328 21.5011 22.0406V27H18.0922V16.9781H21.3625V18.3469H21.4087C21.8656 17.5594 22.9797 16.7297 24.6379 16.7297C28.0879 16.7297 28.7297 18.8062 28.7297 21.5062V27V27Z" fill="white"/>
                  </svg>
                </a>
                <a href={`https://pinterest.com/pin/create/button/?url=${typeof window !== "undefined"? window.location: ''}&media=${post.IMAGE_URL}&description=${post.H1}`} className='share-link' title='Pinterest' target='_blank'>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" fill="#C60022"/>
                    <path d="M20.5 10C16.225 10 12 12.6038 12 16.818C12 19.4979 13.65 21.0207 14.65 21.0207C15.0625 21.0207 15.3 19.97 15.3 19.6731C15.3 19.319 14.3125 18.5653 14.3125 17.0921C14.3125 14.0314 16.8625 11.8615 20.1625 11.8615C23 11.8615 25.1 13.3348 25.1 16.0414C25.1 18.0628 24.2125 21.8544 21.3375 21.8544C20.3 21.8544 19.4125 21.1691 19.4125 20.187C19.4125 18.748 20.5125 17.3547 20.5125 15.8701C20.5125 13.35 16.6 13.8068 16.6 16.8522C16.6 17.4918 16.6875 18.1998 17 18.7823C16.425 21.0435 15.25 24.4125 15.25 26.7423C15.25 27.4618 15.3625 28.1698 15.4375 28.8893C15.5792 29.034 15.5083 29.0187 15.725 28.9464C17.825 26.3197 17.75 25.8058 18.7 22.3683C19.2125 23.2591 20.5375 23.7387 21.5875 23.7387C26.0125 23.7387 28 19.7987 28 16.2469C28 12.4668 24.425 10 20.5 10Z" fill="white"/>
                  </svg>
                </a>
              </div>
            </div>
            <div className='widget-wrapper'>
              <h5>Recent Articles</h5>
              <div className='widget-content recent-articles-widget'>
                {
                  data.allBlogDataJson.edges.slice(0, 5).map((el, i) => {
                    return (
                      <Link to={`/${el.node.slug}`} key={`rp-${i}`}>
                        <div className='related-post-item'>
                          <img src={el.node.IMAGE_URL} className='thumbnail' alt={el.node.H1}/>
                          <p className='title'>
                            {el.node.H1}
                          </p>
                        </div>
                      </Link>
                    )
                  })
                }
              </div>
            </div>
            <div className='vertical-ad-wrapper'>
              {
                data.allAdDataJson.edges.filter((el) => {
                  return el.node.type === "blogpost_sidebar";
                }).map((el, i) => {
                  return(
                    <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                      <img src={el.node.image === ""? vAdPlaceholder: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
        <hr className='light-separator' />
        <div className='pagination-wrapper'>
          <div className='page-link-wrapper'>
            {
              prevPost.slug !== ''?
                <Link to={`/${prevPost.slug}`}>
                  <div className='pagination-subtitle prev-btn'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 5L4 12L11 19M4 12L20 12L4 12Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>
                  Previous Post
                </span>
                  </div>
                  <div className='pagination-post-title'>
                    {prevPost.title}
                  </div>
                </Link>
                : ''
            }
          </div>
          <div className='page-link-wrapper text-end'>
            {
              nextPost.slug !== '' ?
                <Link to={`/${nextPost.slug}`}>
                  <div className='pagination-subtitle next-btn'>
                <span>
                  Next Post
                </span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 5L20 12L13 19M20 12L4 12L20 12Z" stroke="black" strokeWidth="2"
                            strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                  <div className='pagination-post-title'>
                    {nextPost.title}
                  </div>
                </Link>
                : ''
            }
          </div>
        </div>
      </div>


    </Layout>
  );
};

export default BlogPostTemplate;

export const query = graphql`
    query {
        allBlogDataJson(sort: {order: DESC, fields: date}) {
            edges {
                node {
                    slug
                    H1
                    excerpt
                    IMAGE_URL
                }
            }
        }

        allAdDataJson {
            edges {
                node {
                    link
                    image
                    type
                    image_alt
                }
            }
        }

    }
`
